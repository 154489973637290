import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';
import { localDataStore } from '../store/LocalDataStore';

// Helper function to get authorization headers
const getAuthHeaders = () => ({
  'secure': apiSecureHeader,
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'is_admin': true,
});

// Function to handle errors uniformly
const handleError = (error, customMessage) => {
  const errorMessage = error.response?.data?.message || customMessage || error.message || "An unknown error occurred.";
  console.error('Error:', errorMessage);
  
  return Promise.reject({ error: errorMessage });
};

// Fetch languages
export const fetchLanguages = async () => {
  const cachedLanguages = localDataStore.getItem('languages');
  if (cachedLanguages) {
    return cachedLanguages;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}languages/all`, {
      headers: getAuthHeaders(),
    });
    localDataStore.setItem('languages', response.data);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch languages');
  }
};

// Fetch genres for music
export const fetchGenresMusic = async (type) => {
  const cachedGenres = localDataStore.getItem('fetchGenresMusic');
  if (cachedGenres) {
    return cachedGenres;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}categories`, {
      headers: getAuthHeaders(),
      params: { type },
    });
    localDataStore.setItem('fetchGenresMusic', response.data.musics);
    return response.data.musics;
  } catch (error) {
    return handleError(error, 'Failed to fetch music genres');
  }
};

// Fetch genres for podcasts
export const fetchGenresPodcast = async (type) => {
  const cachedGenres = localDataStore.getItem('fetchGenresPodcast');
  if (cachedGenres) {
    return cachedGenres;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}categories`, {
      headers: getAuthHeaders(),
      params: { type },
    });
    localDataStore.setItem('fetchGenresPodcast', response.data.podcasts);
    return response.data.podcasts;
  } catch (error) {
    return handleError(error, 'Failed to fetch podcast genres');
  }
};

// Publish music
export const publishMusic = async (formData, { onUploadProgress, onSuccess, onError }) => {
  try {
    formData.append('id', null);
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}my-music/data/save`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress,
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }
    return handleError(error, 'Failed to publish music');
  }
};

// Update music
export const updateMusic = async (formData, musicId, { onUploadProgress, onSuccess, onError }) => {
  try {
    formData.append('id', musicId);
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}godcast/music/update`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress,
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }
    return handleError(error, 'Failed to update music');
  }
};

// Review music
export const reviewMusic = async (musicId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}godcast/songs/send/review/${musicId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to review music');
  }
};

// Unpublish music
export const unpublishMusic = async (musicId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}godcast/songs/unpublish/${musicId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to unpublish music');
  }
};

// Delete music
export const deleteMusic = async (musicId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}song/delete/${musicId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to delete music');
  }
};

// Archive music
export const archiveMusic = async (musicId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}godcast/songs/archive/${musicId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to archive music');
  }
};

// Fetch published music
export const fetchPublishedMusic = async (page = 1, per_page = 10) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}godcast/songs/fetch`, {
      headers: getAuthHeaders(),
      params: { 
        'type': 'music', 
        'version': '12Oct2024', 
        'page': page,
        'per_page': per_page 
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch published music');
  }
};

// Fetch created episodes
export const fetchCreatedEpisodes = async (page = 1, per_page = 10) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}godcast/songs/fetch`, {
      headers: getAuthHeaders(),
      params: { 
        'type': 'podcasts', 
        'version': '12Oct2024', 
        'page': page,
        'per_page': per_page 
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch published music');
  }
};

// Fetch segments
export const fetchCreatedSegments = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}godcast/segments`, {
      headers: getAuthHeaders(),
      params: { 'type': 'podcasts' },
    });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch segments');
  }
};

// Fetch segment by ID
export const fetchSegmentById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}godcast/segments/${id}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch segment by ID');
  }
};

// Add to segments
export const AddToSegments = async (formData, onUploadProgress, onSuccess, onError) => {
  try {
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);
    formData.append('original_file', formData.get('file'));

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}godcast/segments/add`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress,
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }
    return handleError(error, 'Failed to add segment');
  }
};

// Delete library content
export const deleteLibraryContent = async (musicId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}godcast/library/delete/${musicId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to delete library content');
  }
};

// Publish episode
export const publishEpisode = async (formData, { onUploadProgress, onSuccess, onError }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}episode/publish`,
      formData,
      {
        headers: getAuthHeaders(),
        'Content-Type': 'multipart/form-data',
        'version':'v2.0',
        onUploadProgress,
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || "Failed to publish episode.";
    if (onError) {
      onError(error);
    }
    return Promise.reject({ error: errorMessage });
  }
};

export const updateEpisode = async (formData, episodeId, { onUploadProgress, onSuccess, onError }) => {
  try {
    formData.append('id', episodeId);
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);

    console.log('API URL', `${process.env.REACT_APP_API_BASE_URL}godcast/episode/update`);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}godcast/episode/update`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress,
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }
    return handleError(error, 'Failed to update episode');
  }
};
