import axios from 'axios';

import { apiSecureHeader } from './constants';
import { localDataStore } from '../store/LocalDataStore';
import { toast } from 'react-hot-toast';

// const isDevelopment = process.env.NODE_ENV === 'development';


export async function getOverviewSummary(type) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}godcast/overview/summary`,
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type }
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getOverviewPerformance = async (type,  range) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}godcast/overview/performance`,
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type,  range }
            }
        );
        
        return response.data;
    } catch (error) {
        // console.error('Error fetching overview performance:', error);
        throw error;
    }
};

export async function getAudienceSummary(type) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}godcast/audience/summary`,
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type }
            }
        );
        // if (isDevelopment) console.log(response.data); // Logging the data instead of the response object
        return response.data;
    } catch (error) {
        // console.error('Error fetching audience summary:', error);
        throw error;
    }
}

export const getAudiencePerformance = async (type,  range) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}godcast/audience/performance`,
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type,  range }
            }
        );
        // if (isDevelopment) console.log(response.data);

        return response.data;
    } catch (error) {
        // console.error('Error fetching audience performance:', error);
        throw error;
    }
};
export const getSettings = async () => {
    var settings=localDataStore.getItem('settings');
    if (settings) {
        return settings;
      }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}settings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
      });

      // Check if the response status is 200 (Success)
      if (response.status === 200) {
        localDataStore.setItem('settings',response.data);
        return response.data;
      } else {
        throw new Error('Unexpected status code: ' + response.status);
      }
    } catch (error) {
      // Display error message using toast
      toast.error(error.error);
      return null;
    }
  };