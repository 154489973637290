
import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import '../home/Home.scss';
import profile from '../../Assets/profile.svg';
import terms from '../../Assets/terms.svg';
import guidelines from '../../Assets/guidelines.svg';
import privacy from '../../Assets/privacy.svg';
import support from '../../Assets/support.svg';
import logoutIcon from "../../Assets/logout.svg";
import checked from "../../Assets/checked.png";
import { useAuthStore } from "../../store/store";
import SettingsItem from "./SettingsItem";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/buttons/BackButton";
import { Button } from '@mui/material';
import './Settings.scss';
import { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';
import Loading from "../../components/Loading";

Modal.setAppElement('#root');

const SettingsPage = () => {
    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { logout } = useAuthStore();

    const navigate = useNavigate();

    const openConfirmation = () => setConfirmationIsOpen(true);
    const closeConfirmation = () => setConfirmationIsOpen(false);
    

    const handleLogout = async () => {
        closeConfirmation();
        setLoading(true);
        await logout(); // Ensure logout updates Zustand store
        setLoading(false);
        navigate("/auth");
      };

    return (
        <div className="container">
            <Sidebar />
            <Toaster />
            <main className="content">
                <div style={{ width: '100%' }}>
                    <div className="heading" style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '5px' }}>
                        <BackButton />
                        <h1>Settings</h1>
                    </div>
                    <div style={{ padding: '60px' }}>
                        <SettingsItem name="Profile" icon={profile} onClick={() => navigate('/profile')} />
                        <SettingsItem name="Terms and Conditions" icon={terms} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}godcast/terms`, '_blank')} />
                        <SettingsItem name="Platform Guidelines" icon={guidelines} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}platform-guidelines`, '_blank')} />
                        <SettingsItem name="Privacy Policy" icon={privacy} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}godcast/privacy`, '_blank')} />
                        <SettingsItem name="Support" icon={support} onClick={() => navigate('/support')} />
                    </div>
                    <div className="footer">
                        <Button
                            variant="contained"
                            onClick={openConfirmation}
                            style={{
                                backgroundColor: '#ff4d4d',
                                color: 'white',
                                borderRadius: '30px',
                                padding: '12px 30px',
                                fontSize: '1rem',
                                margin: '0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            LogOut
                            <img
                                src={logoutIcon}
                                alt="Logout"
                                style={{ marginLeft: '10px'}}
                            />
                        </Button>
                    </div>
                </div>
            </main>

            <Modal
                isOpen={confirmationIsOpen}
                onRequestClose={closeConfirmation}
                contentLabel="Confirm Logout"
                style={{
                    content: {
                    maxHeight: "190px",
                    borderRadius: "12px",
                    },
                }}
                >
                <img
                    src={checked}
                    alt=""
                    style={{
                    width: "48px",
                    height: "48px",
                    marginBottom: "-35px",
                    }}
                />
                <h2>Confirmation</h2>
                <p
                    style={{
                    textAlign: "center",
                    marginTop: "-5px",
                    fontSize: "0.85em",
                    }}
                >
                    Are you sure you want to logout?
                </p>
                <div className="button-group">
                    <button
                    type="button"
                    className="sub-can-button"
                    onClick={closeConfirmation}
                    >
                    No
                    </button>
                    <button
                    type="button"
                    className="sub-can-button"
                    onClick={handleLogout}
                    >
                    Yes
                    </button>
                </div>
            </Modal>
            <Loading loading={loading} onClose={() => setLoading(false)} />

        </div>
    );
};

export default SettingsPage;
