import React from 'react';
import { Card } from '@mui/material';
import { styled } from '@mui/system';

// Styled components
const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensure the container takes up at least the full viewport height
    margin: '0',
    boxSizing: 'border-box',
    backgroundColor: '#f5f5f5', // Light background color
    padding: '1rem', // Padding to ensure content doesn't touch edges
});

const CardWrapper = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '1000px', // Max width for larger screens
    height: '600px', // Fixed height for better control
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden', // Ensure no overflow outside the card
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack sections vertically on tablets and smaller screens
        height: 'auto', // Adjust height for stacked layout
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '90%', // Reduce card width on mobile screens
        height: 'auto', // Ensure height adjusts to content on mobile
    },
}));

const ImageSection = styled('div')(({ theme }) => ({
    flex: '1',
    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}godcast/flash)`, // Replace with your image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%', // Ensure the height is 100% of the CardWrapper
    [theme.breakpoints.down('md')]: {
        height: '200px', // Adjust height for tablets
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none', // Hide image section on mobile screens
    },
}));

const ContentSection = styled('div')(({ theme }) => ({
    flex: '1',
    display: 'flex',
    flexDirection: 'column', // Ensure content is stacked vertically
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    boxSizing: 'border-box',
    overflowY: 'auto', // Make content scrollable
    maxHeight: '100vh', // Ensure it doesn't exceed viewport height
    [theme.breakpoints.down('md')]: {
        padding: '1.5rem', // Adjust padding for tablets
    },
    [theme.breakpoints.down('sm')]: {
        padding: '1rem', // Reduce padding for mobile screens
    },
}));

const AuthContainer = ({ children }) => {
    return (
        <Container>
            <CardWrapper>
                <ImageSection />
                <ContentSection>
                    {children}
                </ContentSection>
            </CardWrapper>
        </Container>
    );
};

export default AuthContainer;
