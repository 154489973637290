import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  fetchLanguages,
  publishEpisode,
  fetchGenresPodcast,
} from "../../helper/musicHelper";
import "./PublishEpisode.scss";
import document from "../../Assets/document-upload.svg";
import trash from "../../Assets/trash.svg";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../../components/buttons/RoundedButton";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import Modal from "react-modal";
import Calendar from "react-calendar";
import checked from "../../Assets/checked.png";
import BackButton from "../../components/buttons/BackButton";
import RequiredMark from "../../components/widget/RequiredMark";
import { formatDateForMySQL } from "../../helper/helper";
import { Box, LinearProgress, Typography } from "@mui/material";
import { getSettings } from "../../helper/apiHelper";
import { getS3BucketUrl } from "../../helper/constants";

const PublishEpisode = () => {
  // const isDevelopment = process.env.NODE_ENV === "development";
  const [languages, setLanguages] = useState([]);
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageFileName, setImageFileName] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const openConfirmation = () => setConfirmationIsOpen(true);
  const closeConfirmation = () => setConfirmationIsOpen(false);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    title: Yup.string()
      .trim("Title cannot be empty")
      .required("Title is required")
      .max(100, "Title cannot be more than 100 characters"),
    genre: Yup.string().required("Genre is required"),
    language: Yup.string().required("Language is required"),
    season: Yup.number()
      .typeError("Season must be a number")
      .min(0, "Season cannot be negative")
      .required("Season is required"),
    episodeNumber: Yup.number()
      .typeError("Episode Number must be a number")
      .min(0, "Episode Number cannot be negative")
      .required("Episode Number is required"),
    episodeType: Yup.string().required("Episode type is required"),
    image: Yup.mixed().required("Image is required"),
  });

  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
  };

  const saveEpisodeData = async (temp) => {
    var success = false;
    const dataToSend = temp ?? formData;
    setLoading(true);

    try {
      dataToSend.append(
        "release_date",
        formatDateForMySQL(selectedDate ?? new Date())
      );
      logFormData(dataToSend);

      const response = await publishEpisode(dataToSend, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      toast.success("Episode published successfully");
      setFormData(new FormData());
      success = true;
      navigate("/created-episode");

    } catch (error) {
      toast.error(error.error || "Error publishing episode");
    }
    setLoading(false);

    return success;
  };

  useEffect(() => {
    const loadLanguages = async () => {
      try {
        const langs = await fetchLanguages();
        setLanguages(langs);
      } catch (error) {
        toast.error(error.error || "Error fetching languages");
      }
    };

    const loadGenres = async () => {
      try {
        const gens = await fetchGenresPodcast("podcasts");
        setGenres(gens);
      } catch (error) {
        toast.error(error.error || "Error fetching genres");
      }
    };

    loadLanguages();
    loadGenres();
  }, []);

  return (
    <div className="container">
      <Sidebar />

      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <div className="heading">
            <BackButton onClick={() => navigate(-1)} />
            <p style={{ fontSize: "40px" }}>Publish Episode</p>
          </div>
          <div className="publishContainer">
            <div className="form-container">
              <Formik
                initialValues={{
                  title: "",
                  genre: "",
                  language: "",
                  season: "",
                  episodeNumber: "",
                  episodeType: "",
                  image: null,
                }}
                validateOnMount
                validationSchema={validationSchema}
                onSubmit={async (values, isValid) => {
                  const temp = new FormData();
                  temp.append("title", values.title);
                  temp.append("category_id", values.genre);
                  temp.append("language", values.language);
                  temp.append("season", values.season);
                  temp.append("episode", values.episodeNumber);
                  temp.append("episode_type", values.episodeType);
                  if(values.image){
                    temp.append("image", values.image);
                  }
                  if (values.audio_file){
                    console.log("audio file", values.audio_file);
                    temp.append("audio_file", values.audio_file);
                  }

                  if (isValid && values.audio_file && values.image) {
                    temp.append('active', 1);
                    setFormData(temp);
                    if (values.isSchedule) {
                      setModalIsOpen(true);
                    } else {
                      openConfirmation();
                    }
                    return;
                  }
                  setFormData(temp);
                  openConfirmation();
                  return;
                }}
              >
                {({ values, setFieldValue, isValid, dirty }) => (
                  <Form>
                    <h2 style={{ marginTop: "-30px" }}>Episode Details</h2>
                    <div className="basicinfo">
                      <div className="form-group">
                        <label>
                          Episode Title: <RequiredMark />
                        </label>
                        <Field name="title" type="text" />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Primary Genre: <RequiredMark />
                        </label>
                        <Field name="genre" as="select">
                          <option value="">Select a genre</option>
                          {genres &&
                            genres.map((genre) => (
                              <option key={genre.id} value={genre.id}>
                                {genre.name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="genre"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Choose Language: <RequiredMark />
                        </label>
                        <Field name="language" as="select">
                          <option value="">Select a language</option>
                          {languages &&
                            languages.map((language) => (
                              <option key={language.id} value={language.name}>
                                {language.name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Season: <RequiredMark />
                        </label>
                        <Field name="season" type="number" />
                        <ErrorMessage
                          name="season"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Episode Number: <RequiredMark />
                        </label>
                        <Field name="episodeNumber" type="number" />
                        <ErrorMessage
                          name="episodeNumber"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Select Episode Type: <RequiredMark />
                        </label>
                        <Field name="episodeType" as="select">
                          <option value="">Select an episode type</option>
                          <option value="Regular">Regular</option>
                          <option value="Special">Special</option>
                        </Field>
                        <ErrorMessage
                          name="episodeType"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <h2>
                      Upload Cover <RequiredMark />
                    </h2>
                    <div className="file">
                      <div className="upload-file-group">
                        <label className="file-label">
                          {values.image && (
                            <div>
                              <img
                                src={URL.createObjectURL(values.image)}
                                alt="Cover Preview"
                                className="image"
                              />
                              <img
                                src={trash}
                                alt=""
                                className="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue("image", null);
                                  setImageFileName(null);
                                }}
                              />
                            </div>
                          )}
                          {!values.image && (
                            <>
                              <img
                                src={document}
                                className="upload-icon"
                                alt="Upload Icon"
                              />{" "}
                              Please upload a photo in square format for the
                              best appearance (Max 500 KB).
                              <input
                                name="image"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={(event) => {
                                  if (event.currentTarget.files[0]) {
                                    setFieldValue(
                                      "image",
                                      event.currentTarget.files[0]
                                    );
                                    setImageFileName(
                                      event.currentTarget.files[0]?.name || ""
                                    );
                                  }
                                }}
                              />
                            </>
                          )}
                        </label>
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    {loading && (
                      <div>
                        <Box width="100%" mt={3}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            sx={{ mt: 2 }}
                          />
                          <Typography variant="body2" mt={1}>
                            {uploadProgress}% uploaded
                          </Typography>
                        </Box>
                      </div>
                    )}

                    {!loading && (
                      <div className="buttons">
                        <div className="button-group">
                          {
                            <RoundedButton
                              type="button"
                              text="Cancel"
                              onClick={() => navigate(-1)}
                            />
                          }

                          {
                            <RoundedButton
                              type="submit"
                              text={"Schedule"}
                              onClick={() => setFieldValue("isSchedule", true)}
                              loading={loading}
                              disabled={!isValid}
                            />
                          }

                          {
                            <RoundedButton
                              type="submit"
                              text="Send to Review"
                              onClick={() => setFieldValue("isSchedule", false)}
                              loading={loading}
                              disabled={!isValid}
                            />
                          }
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="footer-spacer">
            <br />
            <br />
            <br />
          </div>
        </div>
      </main>
      {/* Date Picker Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Date"
        style={{
          content: {
            maxHeight: "480px",
            borderRadius: "12px",
            padding: "10px 50px 20px 50px",
          },
        }}
      >
        <h2>Select Releasing Date</h2>
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          minDate={new Date()}
        />
        <div>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeModal}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={() => {
                closeModal();
                openConfirmation();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={confirmationIsOpen}
        onRequestClose={closeConfirmation}
        contentLabel="Confirm Publish"
        style={{
          content: {
            maxHeight: "190px",
            borderRadius: "12px",
          },
        }}
      >
        <img
          src={checked}
          alt=""
          style={{
            width: "48px",
            height: "48px",
            marginBottom: "-35px",
          }}
        />
        <h2>Confirmation</h2>
        <p
          style={{
            textAlign: "center",
            width: "100%",
            margin: "-5px",
            fontSize: "0.85em",
          }}
        >
          Are you sure to publish?
        </p>
        <div>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={() => {
                closeConfirmation();
                saveEpisodeData();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PublishEpisode;
