import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Modal from "react-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  fetchLanguages,
  publishMusic,
  updateMusic,
  fetchGenresMusic,
} from "../../helper/musicHelper";
import "./PublishMusic.scss";
import document from "../../Assets/document-upload.svg";
import trash from "../../Assets/trash.svg";
import checked from "../../Assets/checked.png";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../../components/buttons/RoundedButton";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import RequiredMark from "../../components/widget/RequiredMark";
import BackButton from "../../components/buttons/BackButton";
import { getS3BucketUrl } from "../../helper/constants";
import { formatDateForMySQL } from "../../helper/helper";
import { Box, LinearProgress, Typography } from "@mui/material";
import { getSettings } from "../../helper/apiHelper";

Modal.setAppElement("#root");

const PublishMusic = () => {
  // const isDevelopment = process.env.NODE_ENV === "development";
  const [languages, setLanguages] = useState([]);
  const [genres, setGenres] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFileName, setImageFileName] = useState("");
  const [audioFileName, setAudioFileName] = useState("");
  const [formData, setFormData] = useState(new FormData()); // Use state to store FormData
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [maxAudioFileSizeLimit, setMaxAudioFileSizeLimit] = useState(200); // Default to 200MB

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const openConfirmation = () => setConfirmationIsOpen(true);
  const closeConfirmation = () => setConfirmationIsOpen(false);
  
  const navigate = useNavigate();
  

  // console.log(music);

  const validationSchema = Yup.object({
    title: Yup.string()
      .trim("Title cannot be empty") // Trims whitespace and sets a custom error message
      .required("Title is required")
      .max(100, "Title cannot be more than 100 characters"),
    writer: Yup.string()
      .trim("Writer cannot be empty")
      .required("Writer is required"),
    artists_names: Yup.string()
      .trim("Artists names cannot be empty")
      .required("Artists names is required"),
    genre: Yup.string().required("Genre is required"),
    language: Yup.string().required("Language is required"),
    image: Yup.mixed().required("Image is required"),
    audio_file: Yup.mixed().required("Audio file is required"),
  });

  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
  };

  const saveMusicData = async (temp) => {
    var success = false;
    const dataToSend = temp ?? formData; 
    setLoading(true);

    try {
      dataToSend.append(
        "release_date",
        formatDateForMySQL(selectedDate ??  new Date())
      );
      logFormData(dataToSend);
      
      const response = await publishMusic(dataToSend,{
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });
      
      
      toast.success("Music published successfully");
      setFormData(new FormData());
      success = true;
      navigate("/published-music");
      
    } catch (error) {
      toast.error(error.error || "Error publishing music");
    }
    setLoading(false);
    return success;
  };

  useEffect(() => {
    const loadLanguages = async () => {
      try {
        const langs = await fetchLanguages();
        setLanguages(langs);
      } catch (error) {
        toast.error(error.error || "Error fetching languages");
      }
    };

    const loadGenres = async () => {
      try {
        const gens = await fetchGenresMusic("music");
        setGenres(gens);
      } catch (error) {
        toast.error(error.error || "Error fetching genres");
      }
    };

    loadLanguages();
    loadGenres();
  }, []);
  

  return (
    <div className="container">
      <Sidebar />

      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <div className="heading">
            <BackButton onClick={() => navigate(-1)} />
            <p style={{ fontSize: "40px" }}>Publish Music</p>
          </div>
          <div className="analyticsContainer">
            <div className="form-container">
              <Formik
                initialValues={{
                  title: "",
                  writer: "",
                  artists_names: "",
                  genre: "",
                  language: "",
                  image: null,
                  audio_file: null,
                }}
                validateOnMount
                validationSchema={validationSchema}
                onSubmit={async (values, isValid) => {
                  const temp = new FormData();
                  console.log(values);
                  temp.append("title", values.title);
                  temp.append("writers", values.writer);
                  temp.append("artists_names", values.artists_names);
                  temp.append("name", values.name);
                  temp.append("category_id", values.genre);
                  temp.append("language", values.language);
                  if (values.image){
                    temp.append("image", values.image);
                  }
                  if (values.audio_file){
                    temp.append("audio_file", values.audio_file);
                  }
                  if (isValid && values.audio_file && values.image) {
                    temp.append("active", 1);
                    setFormData(temp);
                    if (values.isSchedule) {
                      setModalIsOpen(true);
                    } else {
                      openConfirmation();
                    }
                    return;
                  }
                  setFormData(temp);
                  openConfirmation();
                  return;
                }}
              >
                {({ values, setFieldValue, isValid, dirty }) => (
                  <Form>
                    <h2 style={{ marginTop: "-30px" }}>Basic Information</h2>
                    <div className="basicinfo">
                      <div className="form-group">
                        <label>
                          Song Title: <RequiredMark />
                        </label>
                        <Field
                          name="title"
                          type="text"
                          className="field-writer"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Song Writer: <RequiredMark />
                        </label>
                        <Field
                          name="writer"
                          type="text"
                          className="field-writer"
                        />
                        <ErrorMessage
                          name="writer"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Artist Name: <RequiredMark />
                        </label>
                        <Field
                          name="artists_names"
                          type="text"
                          className="field-writer"
                        />
                        <ErrorMessage
                          name="artists_names"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Primary Genre: <RequiredMark />
                        </label>
                        <Field
                          name="genre"
                          as="select"
                          className="field-writer"
                        >
                          <option value="">Select a genre</option>
                          {genres &&
                            genres.map((genre) => (
                              <option key={genre.id} value={genre.id}>
                                {genre.name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="genre"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Choose Language: <RequiredMark />
                        </label>
                        <Field
                          name="language"
                          as="select"
                          className="field-writer"
                        >
                          <option value="">Select a language</option>
                          {languages &&
                            languages.map((language) => (
                              <option key={language.id} value={language.name}>
                                {language.name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <h2>Upload Files</h2>
                    <div className="file">
                      <div className="upload-file-group">
                        <p>
                          Upload Cover <RequiredMark />
                        </p>
                        <label className="file-label">
                          {values.image && (
                            <div>
                              <img
                                src={URL.createObjectURL(values.image)}
                                alt="Cover Preview"
                                className="image"
                              />
                              <img
                                src={trash}
                                alt=""
                                className="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue("image", null);
                                  setImageFileName(null);
                                }}
                              />
                            </div>
                          )}
                          {!values.image && (
                            <>
                              <img
                                src={document}
                                className="upload-icon"
                                alt="Upload Icon"
                              />{" "}
                                Please upload a photo in square format for the best
                                appearance (Max 500 KB).
                              <input
                                name="image"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={(event) => {
                                  if (event.currentTarget.files[0]) {
                                    setFieldValue(
                                      "image",
                                      event.currentTarget.files[0]
                                    );
                                    setImageFileName(
                                      event.currentTarget.files[0]?.name || ""
                                    );
                                  }
                                }}
                              />
                            </>
                          )}
                        </label>
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="upload-file-group">
                        <p>
                          Audio File (100 KB to {maxAudioFileSizeLimit}MB) <RequiredMark />
                        </p>
                        <label className="file-label">
                          {values.audio_file && (
                            <div>
                              <p>
                                {audioFileName}{" "}
                              </p>
                              <img
                                src={trash}
                                alt=""
                                className="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue("audio_file", null);
                                  setAudioFileName(null);
                                }}
                              />
                            </div>
                          )}
                          {!values.audio_file && (
                            <>
                              <img
                                src={document}
                                className="upload-icon"
                                alt="Audio Icon"
                              />{" "}
                              Upload
                              <input
                                name="audio_file"
                                type="file"
                                accept="audio/mpeg, audio/mp4, audio/wav" // Restrict to mp3, m4a, and wav
                                onChange={(event) => {
                                  const file = event.target.files[0];

                                  // Check for supported formats (mp3, m4a, wav)
                                  const validAudioTypes = ["audio/mpeg", "audio/x-m4a", "audio/wav"];
                              
                                  if (file && validAudioTypes.includes(file.type)) {
                                    setFieldValue(
                                      "audio_file",
                                      event.currentTarget.files[0]
                                    );
                                    setAudioFileName(
                                      event.currentTarget.files[0]?.name || ""
                                    );
                                  } else {
                                    toast.error(
                                      "Unsupported file format. Please upload mp3, m4a, or wav files."
                                    );
                                    setAudioFileName(null); // Reset file if unsupported
                                    setFieldValue('audio_file',null); // Reset file if unsupported
                                  }
                                }}
                              />
                            </>
                          )}
                        </label>
                        <ErrorMessage
                          name="audio_file"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    {loading && (
                      <div>
                        <Box width="100%" mt={3}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            sx={{ mt: 2 }}
                          />
                          <Typography variant="body2" mt={1}>
                            {uploadProgress}% uploaded
                          </Typography>
                        </Box>
                      </div>
                    )}
                    {!loading && (
                    <div className="buttons">
                      <div className="button-group">
                        {
                          <RoundedButton
                            type="button"
                            text="Cancel"
                            onClick={() => navigate(-1)}
                          />
                        }
                        {
                          <RoundedButton
                            type="submit"
                            text={"Schedule"}
                            onClick={() => setFieldValue("isSchedule", true)}
                            loading={loading}
                            disabled={!isValid}
                          />
                        }
                        {
                          <RoundedButton
                            type="submit"
                            text="Send to Review"
                            onClick={() => setFieldValue("isSchedule", false)}
                            loading={loading}
                            disabled={!isValid}
                          />
                        }
                      </div>
                    </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="footer-spacer">
            <br />
            <br />
            <br />
          </div>
        </div>
      </main>
      {/* Date Picker Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Date"
        style={{
          content: {
            maxHeight: "500px",
            borderRadius: "12px",
            padding: "10px 50px 20px 50px",
          },
        }}
      >
        <h2>Select Releasing Date</h2>
        <Calendar 
          minDate={new Date()} 
          onChange={setSelectedDate} 
          value={selectedDate} 
        />
        <div>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeModal}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={() => {
                closeModal();
                openConfirmation();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={confirmationIsOpen}
        onRequestClose={closeConfirmation}
        contentLabel="Confirm Publish"
        style={{
          content: {
            maxHeight: "190px",
            borderRadius: "12px",
          },
        }}
      >
        <img
          src={checked}
          alt=""
          style={{
            width: "48px",
            height: "48px",
            marginBottom: "-35px",
          }}
        />
        <h2>Confirmation</h2>
        <p
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "-5px",
            fontSize: "0.85em",
          }}
        >
          Are you sure to publish?
        </p>
        <div>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={() => {
                closeConfirmation();
                saveMusicData();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PublishMusic;
