import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "./CreateEpisode.scss";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { apiSecureHeader } from "../../helper/constants";
import RecordItem from "./RecordItem";
import Modal from "react-modal";
import UploadComponent from "./UploadComponent";
import checked from "../../Assets/checked.png";
import styled from "@emotion/styled";
import { Button, CircularProgress } from "@mui/material"; // Added CircularProgress for loading indicator
import { useSpinner } from "../../helper/SpinnerContext";

Modal.setAppElement("#root");

const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  maxWidth: "200px",
}));

const CreateEpisode = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [segments, setSegments] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  const { showSpinner, closeSpinner } = useSpinner();

  const closeConfirmation = () => {
    setSelectedSegment(null);
    setConfirmationIsOpen(false);
  };

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}godcast/segments`,
          {
            headers: {
              secure: apiSecureHeader,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              is_admin: true,
            },
          }
        );
        setSegments(response.data);
      } catch (error) {
        toast.error("Failed to fetch segments");
      } finally {
        setLoading(false); // Turn off loading once fetch is complete
      }
    };
    fetchSegments();
  }, []);

  useEffect(() => {
    return () => {
      // Cleanup to stop audio if playing
      currentlyPlaying?.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    };
  }, []);

  const handlePlayPause = (musicId, audioRef) => {
    if (
      currentlyPlaying &&
      currentlyPlaying.audioRef &&
      currentlyPlaying.id !== musicId
    ) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };


  const handlePublish = () => navigate("/publish-episode");

  const handleDelete = async () => {
    if (!selectedSegment) return;
    setConfirmationIsOpen(false);

    showSpinner();

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}godcast/segments/delete/${selectedSegment.id}`,
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setSegments(segments.filter((segment) => segment.id !== selectedSegment.id));
      toast.success("Segment deleted successfully");
    } catch (error) {
      toast.error("Failed to delete segment");
    } finally {
      closeSpinner();

    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <div className="heading" >
          <p style={{ fontSize: "40px" }}>Create an Episode</p>
          </div>

          {/* Display progress indicator while loading */}
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="episode-list">
              {segments.length > 0 ? (
                <div className="recordList library">
                  {segments.map((record) => (
                    <RecordItem
                      key={record.id}
                      record={record}
                      handleClick={(e) => setAnchorEl(e.currentTarget)}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      handleClose={handleClose}
                      handleDelete={() => {
                        setSelectedSegment(record);
                        setConfirmationIsOpen(true);
                      }}
                      onPlayPause={(audioRef) => handlePlayPause(record.id, audioRef)}
                      currentlyPlaying={currentlyPlaying?.id === record.id}
                    />
                  ))}
                </div>
              ) : (
                <UploadComponent
                  onUploaded={(newSegment) => setSegments((prevSegments) => [...prevSegments, newSegment])}
                />
              )}
            </div>
          )}

          {segments.length > 0 && !loading && (
            <div className="button-group">
              {/* Change text to 'Next' and ensure correct button style */}
              <RoundedButton variant="contained" color="primary" onClick={handlePublish}>
                Next
              </RoundedButton>
            </div>
          )}
        </div>

        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: "190px",
              borderRadius: "12px",
            },
          }}
        >
          <img
            src={checked}
            alt=""
            style={{
              width: "48px",
              height: "48px",
              marginBottom: "-35px",
            }}
          />
          <h2>Confirmation</h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-5px",
              fontSize: "0.85em",
            }}
          >
            Are you sure you want to delete this segment?
          </p>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={handleDelete}
            >
              Yes
            </button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default CreateEpisode;
